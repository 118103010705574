


.coloreTrabajo{
    color:#2e7d32!important;
    font-weight: 600;
}

.imagenListaTrabaja{
    object-fit: cover!important;
    align-content: center!important;
    justify-content: center!important;
    /* display: block!important; */
    height: 100%!important;
}

.swiperTrabajo{
    margin-top: 16px!important;
}

.swiperTrabajo .swiper-wrapper{
    -webkit-transition-timing-function:linear!important; 
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important; 
}

@media (min-width: 600px){

}

.svgTrabajo1{
    position: absolute!important;
    bottom: 0px!important;
    top:0px!important;
    left:0px!important;
    right:0px!important;
    pointer-events: none!important;
}

.opacity20Trabajo{
    opacity: 0.2!important;
}

@media (min-width: 960px){
    .trabajoGridForm{
        
        align-items: start!important;
        justify-content: start!important;
    }
}
@media (min-width: 600px){
.trabajoGridForm{
    flex-direction: row!important;
    justify-content: center!important;
}
}

@media (min-width: 1280px){
    .BoxSvgTrab{
        padding-left: 50px!important;
        padding-right: 50px!important;
    }
}


@media (min-width: 960px){
    .BoxSvgTrab{
        display: flex!important;
    }
}

.trabajoBox2{
    overflow: hidden!important;
    position: absolute!important;
    top: 0px !important;
    right: 0px!important;
    opacity: 0.2!important;
}

.width48Traba{
    width: 48px!important;
}

.formTrabajaConNos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
}

@media (min-width: 600px){
.formaTrabaja2{
/* width: 800px!important; */
margin-left: 0px!important;
margin-right: 0px!important;
}
}

@media (min-width: 960px){
    .paperFormaTraba{
        display: flex!important;
        /* height: 100%!important; */
        /* padding: 64px!important;
        padding-top: 96px!important; */
        border-radius:0px!important;
    }

}

@media (min-width: 600px){
.paperFormaTraba{
    height: auto!important;
    width: auto!important;
    padding: 48px!important;
    border-radius: 16px!important;
}
}

.cardTrabajo:hover{
    background-color:whitesmoke;
cursor: pointer;
}

.listocaTrabajos{
    list-style-type: disc!important;
    padding-left: 15px!important;
}

.listocaTrabajos2{
    list-style-type: disc!important;
   
}

.hoverArribaFlechaBusq:hover .hoverFlechaBusq{
    /* color: #FF9800 !important; */
   /*  color: dimgray !important; */
   color: gray !important;
}


.hoverArribaFlechaBusq2:hover .hoverFlechaBusq{
    /* color: #FF9800 !important; */
   /*  color: dimgray !important; */
   color: gray !important;
}

.trabaMod1{
    height: 100%!important;
    display: block!important;
    position: relative!important;
}

.trabaMod2{
height: 300px!important;
display: flex!important;
justify-content: center!important;
width: 100%!important;
background-color: #f5f5f5!important;
padding-bottom: 24px!important;
}

@media (min-width: 600.01px){
.trabaMod2{
    height: 260px!important;
    padding-bottom: 48px!important;
}
}

.trabaMod3{
    width: 100%!important;
    margin-right: 4vw!important;
    margin-left: 4vw!important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    margin-top: 0px!important;
    max-width: 900px!important;
}



.trabaMod4{
  width: 100%!important;
  text-align: start!important;
  font-size: 32px!important;
  font-weight: 700!important;
  line-height: 1.5!important;
}


@media (min-width: 600.01px){
    .trabaMod4{
        font-size: 40px!important;
    }
    }

    .trabaMod5{
        color: gray !important;
        width: 100%!important;
        text-align: start!important;
        margin-top: 16px!important;
        font-size: 16px!important;
      }
      
      
      @media (min-width: 600.01px){
          .trabaMod5{
            font-size: 18px!important;
          }
          }

          .trabaMod6{
            display: flex!important;
            align-items: center!important;
            margin-top: 24px!important;
          }


          .trabaMod7{
            color: gray !important;
            letter-spacing: -0.025em!important;
            margin-left: 16px!important;
            font-size: 16px!important;
          }
          
          
          @media (min-width: 600.01px){
              .trabaMod7{
                font-size: 18px!important;
              }
              }

.trabaMod8{
width: 100%!important;
padding-left: 4vw!important;
padding-right: 4vw!important;
}

@media (min-width: 900.01px){
    .trabaMod8{
     width:calc(100%)!important;
    }
    }

    .trabaMod9{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        border-color: #B1B1B1!important;
        border-width: 0.5px;
        padding-left: 4vw!important;
        padding-right: 4vw!important;
      }
      
      
      @media (min-width: 600.01px){
          .trabaMod9{
            margin-bottom: 24px!important;
          }
          }

          .trabaMod10{
            width: 100%!important;
            justify-content: center!important;
            align-items: center!important;
           color: #A9A9A9!important;
            font-size: 16px!important;
            text-align: start!important;
            padding-right: 0px!important;
            margin: 50px!important;
            margin-top: 30px!important;
            margin-bottom: 0px!important;
            margin-left: 0px!important;
            margin-right: 0px!important;
            line-height: 1.5!important;
            display: inline-block!important;
          }
          
          
          @media (min-width: 900.01px){
              .trabaMod10{
                width: calc(100% - 8vw)!important;
                margin-left: 50px!important;
            margin-right: 50px!important;
              }
              }

              @media (min-width: 600.01px){
                .trabaMod10{
                    font-size: 18px!important;
                 
                }
                }

                .trabaMod11{
                    width: 100%!important;
                    font-size: 32px!important;
                    font-weight: 700!important;
                    font-family: 'Segoe UI', Arial, sans-serif!important;
                    padding-right: 0px!important;
                    margin: 50px!important;
                    align-items: center!important;
                    justify-content: start!important;
                    display: flex!important;
                  
                    margin-top: 20px!important;
                    margin-bottom: 20px!important;
                    margin-left: 0px!important;
                    margin-right: 0px!important;
                    line-height: 1.2!important;
                    
                    
                   

                  }
                  
                  
                  @media (min-width: 900.01px){
                      .trabaMod11{
                        width: calc(100% - 100px)!important;
                        margin-left: 50px!important;
                    margin-right: 50px!important;
                      }
                      }
        
               